import { useState } from "react";
import { getUserList } from "../../services/users";
import {
  Box,
  Divider,
  FormControl,
  Stack,
  TextField,
  Typography,
  Select,
  Checkbox,
  InputAdornment,
  InputBase,
} from "@mui/material";
import {
  ButtonStack,
  DealTeamSearch,
  InfoText,
  SearchItem,
  StyledChip,
  StyledFormControlLabel,
  StyledIconButton,
  StyledMenuItem,
  StyledSecondaryButton,
  StyledTooltip,
} from "./styles";
import { UUID } from "crypto";
import { Button, Icon } from "@alterdomus-analytics/dna-ui";
import { useAppSelector } from "../../redux/hooks";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { CaretDown, Info, X } from "@phosphor-icons/react";
import { updateDealTeam } from "../../services/deals";
import { DealTeamMembersForm, EditDealTeamProps, IOptionDealTeam, UserOptions, UserRole } from "./types";

export const teamRoleOptions: UserRole[] = [
  { id: UserOptions.OWNER, name: "Make Deal Owner" },
  { id: UserOptions.MEMBER, name: "Team Member" },
  { id: UserOptions.REMOVE, name: "Remove from deal" },
];

export const EditDealTeam = ({ companyId, orgId }: EditDealTeamProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchOptions, setSearchOptions] = useState<IOptionDealTeam[]>([]);
  const [usersSelected, setUsersSelected] = useState<IOptionDealTeam[]>([]);
  const [allSelectedUsers, setAllSelectedUsers] = useState<IOptionDealTeam[]>([]);
  const [searchTimerId, setSearchTimerId] = useState<number | null>(null);
  const [loadingSearchOptions, setLoadingSearchOptions] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const userState = useAppSelector((state) => state.app.user);
  const { handleSubmit, control, reset } = useForm<DealTeamMembersForm>();
  const { fields, remove, append } = useFieldArray({
    control,
    name: "selectedUsers", // name for our Field Array
  });

  const currentUser = [
    {
      label: userState.name ? userState.name : "",
      value: userState.name ? userState.name : "",
      user_id: userState.id ? userState.id : "",
      email: userState.email ? userState.email : "",
    },
  ];

  function onSearchChanged(event: React.ChangeEvent<HTMLInputElement>) {
    searchTimerId && window.clearTimeout(searchTimerId);
    if (event?.type === "change") {
      let search = event.target?.value;
      search = search ? search : "";
      setSearchTerm(search);
      if (search.trim()) {
        const timerId = window.setTimeout(async () => {
          updateSearchOptions(search);
        }, 300);
        setSearchTimerId(timerId);
        return;
      }
    }
  }

  async function updateSearchOptions(search?: string) {
    setLoadingSearchOptions(true);

    try {
      const response = await getUserList(orgId ? orgId : "", search);

      // Parse the response and create options
      const options = response.value.map((user: { id: UUID; displayName: string; mail: string }) => ({
        user_id: user.id,
        label: user.displayName,
        value: user.displayName,
        email: user.mail,
      }));

      // Set the search options and loading state
      setSearchOptions(options);
      setLoadingSearchOptions(false);
    } catch (error) {
      console.error("Error fetching user list:", error);
      // Handle error here
      setLoadingSearchOptions(false);
    }
  }

  function onUserSelected(value: any) {
    let newValue: IOptionDealTeam[] | null;
    if (value === null || value.length === 0) {
      newValue = [];
      setSearchOptions([]);
    } else {
      // Handle selected values
      newValue = [
        ...value.map((option: IOptionDealTeam) => ({
          label: option.label,
          value: option.value,
          user_id: option.user_id,
          email: option.email,
          isClearable: true,
        })),
      ];
    }
    // Set the new value
    setUsersSelected(newValue);
    setSearchTerm("");
  }

  function matchUser(teamMember: IOptionDealTeam) {
    const userExists = fields.find((field) => field.userId === teamMember.id);
    if (!userExists) {
      append({
        label: teamMember.label,
        value: teamMember.value,
        email: teamMember.email,
        user_id: teamMember.user_id,
      });
    }
  }

  function onUsersAdd(e: any) {
    const selectedUsers: IOptionDealTeam[] = usersSelected
      ? usersSelected.map((option) => ({
          label: option.label,
          value: option.value,
          user_id: option.user_id,
          email: option.email,
        }))
      : [];
    selectedUsers.forEach((u) => matchUser(u));
    // Store all selected users
    setAllSelectedUsers((prevUsers) => [...prevUsers, ...selectedUsers]);

    // Reset the selected users in the Autocomplete after adding them
    setIsChecked(false);
    setSearchTerm("");
    setSearchOptions([]);
    setUsersSelected([]);
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      setIsChecked(true);
      setUsersSelected(currentUser);
      append({
        label: currentUser[0].label,
        value: currentUser[0].value,
        email: currentUser[0].email,
        user_id: currentUser[0].user_id,
      });
      setAllSelectedUsers((prev) => [...prev, ...currentUser]);
      setUsersSelected([]);
    } else {
      setIsChecked(false);

      setUsersSelected([]);
    }
  }

  const submitForm = async (data: any) => {
    updateDealTeam(companyId, data.selectedUsers);

    setAllSelectedUsers([]);
    setIsChecked(false);
    reset({});
    remove();
  };

  function handleClear(data: any) {
    reset({});
    remove();
    setIsChecked(false);
    setUsersSelected([]);
    setAllSelectedUsers([]);
  }

  return (
    <Stack>
      <Stack direction={"row"} mb={"1.5rem"} alignItems={"center"}>
        <InfoText variant="body2">How does it work?</InfoText>
        <StyledTooltip
          title="Assign users who are already in the system to this deal. To invite new users to sign up, contact ___________. The deal owner will receive an email prompting them to build the deal profile."
          placement="right"
          leaveDelay={700}
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                maxWidth: "270px",
                fontSize: "0.75rem",
                fontWeight: 400,
                backgroundColor: "#3C3C3B",
              },
            },
            arrow: {
              sx: {
                color: "#3C3C3B",
              },
            },
          }}
        >
          <StyledIconButton>
            <Info size={20} color="#1A50DD" weight="fill" />
          </StyledIconButton>
        </StyledTooltip>
      </Stack>

      <form onSubmit={handleSubmit(submitForm)} encType="multipart/form-data">
        <Stack direction={"row"} mb={"0.5rem"} alignItems={"center"}>
          <DealTeamSearch
            disableCloseOnSelect
            size="small"
            multiple
            forcePopupIcon={false}
            clearOnBlur={true}
            value={usersSelected || ""}
            inputValue={searchTerm}
            options={searchOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Add people"
                //  placeholder="Add people"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <CaretDown />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            getOptionLabel={(option: any) => {
              return typeof option === "string" ? option : option.label;
            }}
            renderOption={(props, option: any) => {
              return (
                <li {...props} key={option.user_id}>
                  <SearchItem display={"flex"} flexDirection={"column"} e2e-test-id={`user-search-option-${option.label}`}>
                    <Typography variant="body1">{option.label}</Typography>
                    <Typography variant="body2">{option.email}</Typography>
                  </SearchItem>
                </li>
              );
            }}
            renderTags={(tagValue, getTagProps) => {
              return tagValue.map((option: any, index) => (
                <StyledChip
                  {...getTagProps({ index })}
                  label={option.label}
                  size="small"
                  deleteIcon={<Icon component={X} size={12} />}
                />
              ));
            }}
            isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
            loading={loadingSearchOptions}
            disableClearable={true}
            onChange={(e, value) => onUserSelected(value)}
            onInputChange={(e: any) => onSearchChanged(e)}
            e2e-test-id={`user-search-input`}
          />
          <StyledSecondaryButton
            label="Add"
            variantType="secondary"
            size="small"
            onClick={(e) => onUsersAdd(e)}
            e2e-test-id={`add-users-btn`}
          />
        </Stack>
        <StyledFormControlLabel
          control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} size="small" />}
          label="Assign deal to myself"
          e2e-test-id={`assign-current-user-checkbox`}
        />
        {allSelectedUsers.length > 0 && <Divider />}
        <Stack paddingX={5}>
          {allSelectedUsers.map((field, i) => {
            return (
              <Box key={i} e2e-test-id={`selected-user-${i}`}>
                <Stack direction="row" sx={{ height: 56 }} alignItems="center">
                  <Typography variant="subtitle1" flexGrow={1} component="p" e2e-test-id={`selected-user-${i}-name`}>
                    {field.value}
                  </Typography>
                  <Controller
                    name={`selectedUsers.${i}.role`}
                    control={control}
                    defaultValue={UserOptions.MEMBER}
                    rules={{ required: "This field is required" }}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                        <Select
                          id="role"
                          name="role"
                          placeholder={"Role"}
                          value={teamRoleOptions.find((t) => t.id === value)?.id || ""}
                          onChange={(value) => onChange(value)}
                          error={error && true}
                          IconComponent={CaretDown}
                          input={
                            <InputBase
                              sx={{
                                border: "none",
                                fontSize: "0.875rem",
                                "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input": { paddingRight: "1.5rem" },
                              }}
                            />
                          }
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          }}
                          e2e-test-id={`selected-user-${i}-role-select`}
                        >
                          {teamRoleOptions.map(({ id, name: optionName }) => (
                            <StyledMenuItem value={id} key={id} e2e-test-id={`selected-user-${i}-role-${optionName}`}>
                              {optionName}
                            </StyledMenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Stack>
                {i < allSelectedUsers.length - 1 && <Divider />}
              </Box>
            );
          })}
        </Stack>
        {allSelectedUsers.length > 0 && (
          <>
            <Divider />
            <ButtonStack flexDirection={"row"}>
              <Button label="Cancel" variantType="tertiary" onClick={handleClear} />
              <Button label="Done" variantType="primary" type="submit" />
            </ButtonStack>
          </>
        )}
      </form>
    </Stack>
  );
};
