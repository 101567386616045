import { Button } from "@alterdomus-analytics/dna-ui";
import {
  Autocomplete,
  MenuItem,
  Stack,
  styled,
  Typography,
  Tooltip,
  IconButton,
  Box,
  FormControlLabel,
  Chip,
} from "@mui/material";

export const ListItemPartStyled = styled("span")(() => ({
  fontWeight: 400,
}));

// EditDealTeam
export const DealTeamSearch = styled(Autocomplete)(() => ({
  width: "calc(100% - 0.5rem)",
  marginRight: "0.5rem",

  "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
    paddingTop: "7px",
    paddingLeft: "10px",
    paddingBottom: "6px",
    paddingRight: "16px",
  },
  "& > div > div > fieldset": {
    borderColor: "#b4b7b7",
  },
  "& > div > div > input": {
    fontSize: "14px",
  },
}));

export const SearchItem = styled(Box)`
  & .MuiTypography-root.MuiTypography-body1 {
    font-size: 0.875rem;
    font-weight: 600;
    color: #313636;
  }
  & .MuiTypography-root.MuiTypography-body2 {
    font-size: 0.75rem;
    font-weight: 400;
    color: #586463;
  }
`;

export const StyledChip = styled(Chip)(({ theme }) => ({
  fontSize: theme.spacing(1.5),
  fontWeight: 500,
  borderRadius: theme.spacing(1.75),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  marginTop: 0,
  marginBottom: 0,
  backgroundColor: "#E4E8F2",
  height: 28,
  border: "2px solid transparent",

  "&.MuiChip-deletable": {
    paddingRight: 0,
    marginTop: 0,
    marginBottom: 0,
  },

  "& .MuiChip-deleteIcon": {
    padding: theme.spacing(1),
    margin: 0,
    width: 28,
    height: 28,
    fill: "#292727",
    borderRadius: 28,
    marginRight: -2,
  },

  "& .MuiChip-label": {
    color: "#292727",
    padding: 0,
  },

  "&:hover": {
    backgroundColor: "#c9d3ea",

    "& .MuiChip-deleteIcon": {
      color: "#292727",
    },
  },

  "&:focus-visible": {
    borderColor: theme.palette.text.primary,
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    font-size: 0.875rem;
  }

  & > span > svg {
    fill: #1a50dd;
  }
`;
export const InfoText = styled(Typography)`
  font-size: 1rem;
  color: #586463;
  margin-right: 0.5rem;
`;

export const StyledTooltip = styled(Tooltip)``;

export const StyledIconButton = styled(IconButton)`
  padding: 0px;
`;

export const StyledSecondaryButton = styled(Button)`
  height: 2rem;
  padding: 0.5rem 1rem;
  color: #1a50dd;
  font-size: 0.75rem;
  border-radius: 100px;
  border: 1px solid rgba(26, 80, 221, 0.6);
  background-color: #fff;
  text-transform: none;
`;
export const StyledMenuItem = styled(MenuItem)({
  fontFamily: "Inter, sans-serif",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  paddingTop: "4px",
  paddingBottom: "4px",
  "&.Mui-selected": {
    fontWeight: 600,
    backgroundColor: "#E8EAEE",
  },
});

export const ButtonStack = styled(Stack)`
  justify-content: flex-end;
  margin-top: 1rem;
  .MuiButtonBase-root.MuiButton-root {
    color: #1a50dd;
    :first-of-type {
      margin-right: 0.5rem;
    }
  }
  .MuiButton-root.MuiButton-contained {
    color: #fff;
    background-color: #1a50dd;
  }
`;
