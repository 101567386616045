import { Company, ReportingPeriods } from "../../types";
import { formatCurrentPeriod, formatDate } from "../../utils/formatDate";
import { DealDetail, EditableDealDetail, DealDetailValueTypes } from "../DealDetail";
import { CardContent, Typography, CardHeader } from "@mui/material";
import { DealSummaryCard } from "./styles";
import { Folders, Money, MapPin, FolderSimple } from "phosphor-react";

type DealSummaryPaneProps = {
  companyId: string;
  deal: Company;
  reportPeriod: ReportingPeriods;
  orgId?: string;
};

const DealSummaryPane = ({ companyId, deal, reportPeriod, orgId }: DealSummaryPaneProps) => {
  return (
    <DealSummaryCard variant="outlined" sx={{ maxWidth: "100%" }}>
      <CardHeader
        title={
          <Typography variant="h5" component="div">
            {"Details"}
          </Typography>
        }
        e2e-test-id={"key-deal-container"}
      />
      {deal && (
        <CardContent>
          <DealDetail
            label={"Company Trade Name"}
            value={deal.company_trade_name}
            testKey={"company_trade_name"}
            icon={FolderSimple}
          />
          <DealDetail label={"Company Legal Name"} value={deal.deal_name} testKey={"company-legal-name"} icon={Folders} />
          <DealDetail label={"Currency"} value={deal.currency} testKey={"currency"} icon={Money} />
          <DealDetail label={"Country"} value={deal.country} testKey={"country"} icon={MapPin} />
          <DealDetail label={"Sector"} value={deal.sector} testKey={"sector"} />
          <DealDetail label={"Sponsor"} value={deal.sponsors?.join(", ")} testKey={"sponsor"} />
          <EditableDealDetail
            label={"Internal Rating"}
            value={`${deal.internal_rating !== null && deal.internal_rating !== undefined ? deal.internal_rating : ""}`}
            testKey={"internal-rating"}
            companyId={companyId}
          />

          <EditableDealDetail
            label={"Deal Team"}
            value={deal.deal_team?.map((m) => m.user_name).join(", ")}
            testKey={"deal-team"}
            companyId={companyId}
            orgId={orgId}
          />
          <EditableDealDetail
            label={"Co-investor"}
            value={deal.co_investors?.map((ci) => ci.trim()).join(", ")}
            testKey={"coinvestor"}
            companyId={companyId}
          />
          <EditableDealDetail
            label={"Transaction Date"}
            value={deal.transaction_date !== null ? formatDate(deal.transaction_date) : ""}
            testKey={"transaction-date"}
            valueType={DealDetailValueTypes.date}
            companyId={companyId}
          />
          <DealDetail label={"Financial Year End"} value={formatDate(deal.financial_year_end)} testKey={"fin-year-end"} />
          <DealDetail
            label={"Reporting Period Cycle"}
            value={deal.reporting_cycle === "m" ? ReportingPeriods.Monthly : ReportingPeriods.Quarterly}
            testKey={"period"}
            styleProps={{ textTransform: "capitalize" }}
          />
          <DealDetail
            label={"Current Period"}
            value={formatCurrentPeriod(new Date(deal.current_period), reportPeriod)}
            testKey={"current-period"}
          />
        </CardContent>
      )}
    </DealSummaryCard>
  );
};

export default DealSummaryPane;
