import { IOption } from "@alterdomus-analytics/dna-ui";

export interface EditDealTeamProps {
  companyId: string;
  orgId: string;
}

export interface IOptionDealTeam extends IOption {
  label: string;
  value: string;
  user_id: string;
  email: string;
  role?: string;
}

export interface DealTeamMembersForm {
  selectedUsers: IOptionDealTeam[];
}

export interface DealTeamMembers {
  label: string;
  value: string;
  user_id: string;
  email: string;
  role: string;
}

export interface UserRole {
  id: string;
  name: string;
}

export enum UserOptions {
  OWNER = "0",
  MEMBER = "1",
  REMOVE = "2",
}
